<template>
  <div>
      <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加供应商</el-breadcrumb-item>
    </el-breadcrumb>
    <hr style="margin-bottom:20px">
  <el-form ref="formData" :model="formData"  label-width="120px" :rules="rule">
      <el-form-item>
          <div class="title"><span></span>供应商基本信息</div>
          </el-form-item>
          <el-row>
  <el-col :span="6"> <el-form-item label="供应商名称" prop="supplierName">
        <el-input v-model="formData.supplierName" style=""></el-input>
    </el-form-item></el-col>
  <el-col :span="6"> <el-form-item label="简称" prop="secondName">
        <el-input v-model="formData.secondName" style=""></el-input>
    </el-form-item></el-col>
</el-row>
   
   
    <el-form-item label="街道" prop="streetCode">
      <el-select placeholder="请选择省" v-model.trim="provinceValue" class="widthStyle">
            <el-option
                  v-for="item in provinceList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                  class="widthStyle"
                >
                </el-option>
              </el-select>
              <el-select placeholder="请选择市" v-model.trim="cityValue" class="widthStyle">
                <el-option
                  v-for="item in cityList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                  class="widthStyle"
                >
                </el-option>
              </el-select>
              <el-select placeholder="请选择地区" v-model.trim="areaValue" class="widthStyle">
                <el-option
                  v-for="item in areaList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                  class="widthStyle"
                >
                </el-option>
              </el-select>
              <el-select placeholder="请选择街道" v-model="formData.streetCode" class="widthStyle">
                <el-option
                  v-for="item in streetList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                  class="widthStyle"
                >
                </el-option>
              </el-select>
    </el-form-item>
    <el-row>
  <el-col :span="12"><el-form-item label="详细地址" prop="address">
      <el-input v-model="formData.address" style="width:104%"></el-input>
    </el-form-item></el-col>
</el-row>
    
    <el-form-item label="结算方式" prop="payWay">
      <el-select placeholder="请选择结算方式" v-model="formData.payWay" class="width2">
                <el-option
                class="wdith2"
                  v-for="item in options"
                  :key="item.value"
                  :label="item.way"
                  :value="item.value"
                >
                </el-option>
              </el-select>
    </el-form-item>
        <el-form-item>
    <div class="title"><span></span>联系人信息</div>
    </el-form-item>
    <el-row>
  <el-col :span="6"> <el-form-item label="类型" prop="supplierType">
      <el-select placeholder="请选择类型" v-model="formData.supplierType" class="width2">
                <el-option
                class="width2"
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.way"
                  :value="item.value"
                >
                </el-option>
              </el-select>
    </el-form-item></el-col>
  <el-col :span="6"><el-form-item label="联系人" prop="linkMan">
      <el-input v-model="formData.linkMan"></el-input>
    </el-form-item></el-col>
</el-row>
   
        <el-row>
  <el-col :span="6"> <el-form-item label="联系电话" prop="telPhone">
      <el-input v-model="formData.telPhone"></el-input>
    </el-form-item></el-col>
  <el-col :span="6"><el-form-item label="邮箱" prop="email">
      <el-input v-model="formData.email"></el-input>
    </el-form-item></el-col>
</el-row>
    
    
            <el-form-item>
    <div class="title"><span></span>账户信息</div>
    </el-form-item>
    <el-form-item label="收款账户" prop="">
      <el-input v-model="formData.account"></el-input>
    </el-form-item>
    <el-form-item label="收款账号" prop="">
      <el-input v-model="formData.accountNo"></el-input>
    </el-form-item>
    <el-form-item label="收款银行" prop="">
      <el-input v-model="formData.bank"></el-input>
    </el-form-item>
    <el-form-item label="备注">
      <el-input v-model="formData.remark" type="textarea"  style="width:340px;" :rows="7"></el-input>
    </el-form-item>
    <el-form-item>
        <el-button size="mini" type="primary" style="margin:20px 20px 0 0" @click="sumitForm('formData')">确定</el-button>
        <el-button size="mini" type="primary" style="margin:20px 0" @click="$router.go(-1)">返回</el-button>
    </el-form-item>
  </el-form>
  </div>
</template>
<script>
import { addSupplierAPI,supplierDetailAPI } from "../../api/Statistics";
import {getAllProvince,getCityByProvince,getAreaByCity,getStreetByArea} from "../../api/address";
export default {
    data(){
        return{
        options:[{value:1,way:'月付'},
                {value:2,way:'月结'},
                {value:3,way:'季结'},
                {value:4,way:'周结'}],
        options2:[
            {value:1,way:'财务'},
            {value:2,way:'运营'}],
            provinceValue: "440000",
            cityValue: "440900",
            areaValue: "440923",
            streetValue: "",
            provinceList: [],
            cityList: [],
            areaList: [],
            streetList: [],
            supplierId:'',
            formData:{
                supplierId:'0',
                supplierName:'',
                secondName:'',
                accountName:'',
                streetCode:'',
                address:'',
                payWay:'',
                supplierType:'',
                linkMan:'',
                telPhone:'',
                email:'',
                account:'',
                accountNo:'',
                bank:'',
                remark:''
            },
            rule:{
                supplierName:[{ required:true,trigger:'blur',message:'请填写信息'}],
                linkMan:[{ required:true,trigger:'blur',message:'请填写信息'}],
                secondName:[{ required:true,trigger:'blur',message:'请填写信息'}],
                address:[{ required:true,trigger:'blur',message:'请填写信息'}],
                streetCode:[{ required: true, message: '请选择', trigger: 'change' }],
                payWay:[{ required: true, message: '请选择', trigger: 'change'}],
                supplierType:[{ required: true, message: '请选择', trigger:'change'}],
                email:[
                        { required: true,message:'请输入正确的邮箱', trigger: 'blur'},
                        {pattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,message: '请輸入正确的格式', trigger: 'blur'}
                    ],
                telPhone:[
                        { required: true,message:'请输入正确的手机号', trigger: 'blur'},
                        {pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号', trigger: 'blur'},
                    ],
            }
        }
    },
    created() {
    this.formData.supplierId = this.$route.query.id
    if(this.formData.supplierId!=='0'){
        this.getSupplierDetail()
    }
    this.getProvinceList();
    this.getCityList();
    this.getareaList();
    this.getstreetList();
    },
    watch: {
    provinceValue: async function () {
      this.cityValue = "";
      this.areaValue = "";
      this.formData.streetCode = "";
      const { data } = await getCityByProvince({
        provinceCode: this.provinceValue,
      });
      this.cityList = data.data;
    },
    cityValue: async function () {
      this.areaValue = "";
      this.formData.streetCode = "";
      const { data } = await getAreaByCity({
        cityCode: this.cityValue,
      });
      console.log(data.data);
      this.areaList = data.data;
    },
    areaValue: async function () {
      this.formData.streetCode = "";
      const { data } = await getStreetByArea({
        areaCode: this.areaValue,
      });
      this.streetList = data.data;
    },
  },
    methods:{
    async getSupplierDetail(){
    const {data} = await supplierDetailAPI({supplierId:this.formData.supplierId})
    if(data.code==0){
        this.formData = data.data[0]
    }
    },
    async addSupplier(){
        let that = this
        const {data} = await addSupplierAPI(this.formData)
            if(data.code==0){
              this.$message({
              type: "success",
              message: data.msg
            });
            setTimeout(() => {
                that.$router.go(-1)
            }, 500);
            }else{
              this.$message({
              type: "error",
              message:data.msg
            });
            }
        },
    sumitForm(formData){
        this.$refs[formData].validate((valid) => {
          if (valid) {
            console.log(valid,'valid')
            this.addSupplier()
          } else {
              this.$message({
                message:'请检查必选项',
                type:'error'
              })
          }
        })
           
        },
    async getCityList() {
      const { data } = await getCityByProvince({
        provinceCode: this.provinceValue,
      });
      this.cityList = data.data;
    },
    async getareaList() {
      const { data } = await getAreaByCity({
        cityCode: this.cityValue,
      });
      this.areaList = data.data;
    },
    async getstreetList() {
      const { data } = await getStreetByArea({
        areaCode: this.areaValue,
      });
      this.streetList = data.data;
    },
    async getProvinceList() {
      const { data } = await getAllProvince();
      this.provinceList = data.data;
    }
    }
}
</script>
<style lang="less" scoped>
    .el-input__inner{
    width:300px !important;
}
.title{
    font-weight: bold;
    margin-left: -90px;
    span{
        height:13px;
        width: 5px;
        background-color: #3082fd;
        display: inline-block;
        margin-right: 5px;
    }

}
.widthStyle{
  width: 176px;
  // .el-input__inner{
  //   width: 176px !important;
  // }
}
.el-input{
  width: 300px;
}
.width2{
  width: 300px;
  /deep/.el-input__inner{
    width: 300px !important;
  }
}
.el-input{
  width: 300px;
}
</style>